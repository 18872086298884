import {
  FILTERS as insightsFilters,
  TABS as insightsTabs,
  WB_LIST_TYPE,
} from './app-common/Insights/constants';
import {
  CLIENT_ACTIONS,
  DEVICE_AGENT_STATUS_FILTERS,
  ON_SITE_STATUSES,
  TABS as clientsTabs,
} from './app-common/Clients/constants';
import {TABS as accessManagementTabs} from './app-common/AccessManagement/constants';

import {
  AUTHENTICATION_PROVIDER_TYPES,
  CLIENT_STATUSES,
  MOVE_TO_PERMIT_LIST_OPTIONS,
  ORGANIZATION_OPERATOR_PERMISSIONS,
  RELAY_STATUSES,
  USER_ROLES,
} from './constants';

export const translationObject = {
  en_EN: {
    navMenu: {
      dashboard: 'Dashboard',
      config: 'Config',
      resolver: 'Resolver',
      logServer: 'Log server',
      dns: 'Dns',
      operator: 'Operator',
      allowBlock: 'Allow/Block',
      log: 'Log',
      site: 'Site',
      policy: 'Policy',
      whitelist: 'White/Black list',
      insights: 'Insights',
      customLists: 'Custom lists',
      clients: 'Clients',
      organizations: 'Organizations',
      blockPage: 'Block Page',
    },
    pages: {
      '/dashboard': 'Dashboard',
      '/ip-list': 'IPs list',
      '/log': 'Log',
      '/policy': 'Policy',
      '/root-certificate': 'Root Certificate',
      '/download-relay': 'Download Relay',
      '/site': 'Site',
      '/subscription': 'Subscription',
      '/white-black-list': 'Custom lists',
      '/custom-lists': 'Custom lists',
      '/access-management': 'Access Management',
      '/api-access-management': 'API Access Management',
      '/profile': 'Profile',
      '/notifications-management': 'Configure Notifications',
      '/cancelled-account': 'Cancelled',
      '/insights': 'Insights',
      '/clients': 'Clients',
      '/organizations': 'Organizations',
      '/block-page': 'Block Page',
    },
    logoText: {
      sidebar: 'ScoutDNS',
    },
    operatorLayout: {
      notifications: {
        'read-only': 'You have read-only rights in this view, if you want to edit any objects, '
          + 'you must select the appropriate organization',
      },
    },
    headerUser: {
      accountOption: {
        billing: 'Billing',
        changePassword: 'Password',
        logout: 'Logout',
        name: 'Profile',
        subscription: 'Subscription',
        accessManagement: 'Access Management',
        apiAccessManagement: 'API Access Management',
        profile: 'Profile info',
        notificationsManagement: 'Configure Notifications',
        blockPage: 'Configure Block Page',
      },
      notificationsOption: {
        name: 'Notifications',
      },
      settingsOption: {
        name: 'Settings',
      },
      helpAndSupportOption: {
        ipList: 'IPs list',
        knowledgeBase: 'Knowledgebase',
        name: 'Help and support',
        openTicket: 'Open a ticket',
        rootCertificate: 'Root certificate',
        systemStatus: 'System status',
        setupGuide: 'Setup guide',
        downloadRelay: 'Download Relay',
      },
      userAccounts: {
        accountName: 'Account name',
        name: 'Accounts',
        role: 'Role',
      },
      userOrganizations: {
        all: 'All organizations',
      },
    },
    notificationBanner: {
      activeTrial: {
        infoWithCountDays: 'You have %{countDays} day(s) remaining in trial.',
        infoWithLink: 'Please {linkText} to subscribe.',
        linkText: 'contact us',
      },
      expiredTrial: {
        info: 'Your trial has expired.',
        infoWithLink: 'Please {linkText} to subscribe.',
        linkText: 'contact us',
      },
      suspended: {
        info: 'Your account has been suspended.',
        infoWithLink: 'Please make payment or {linkText}.',
        linkText: 'contact us',
      },
      cancelled: {
        info: 'Your account has been cancelled.',
        infoWithLink: 'For help please {linkText}.',
        linkText: 'contact us',
      },
    },
    authCodeChecker: {
      authCodeFailed: 'Code check failed, operation cancelled',
    },
    dashboard: {
      filters: {
        ALL_CAMPUSES: 'All sites',
        LAST_HOUR: 'Last Hour',
        LAST_24_HOURS: 'Last 24 Hours',
        TODAY: 'Today',
        YESTERDAY: 'Yesterday',
        LAST_WEEK: 'Last Week',
        THIS_WEEK: 'This Week',
        LAST_7_DAYS: 'Last 7 Days',
        LAST_MONTH: 'Last 30 Days',
        THIS_MONTH: 'This Month',
      },
      orgsAndSitesStats: {
        title: 'Entity counts',
        orgs: 'Orgs',
        sites: 'Sites',
      },
      statusStats: {
        title: 'Status',
        networksUp: 'WANs up',
        networksDown: 'WANs down',
      },
      clientCountStats: {
        title: 'Clients',
        online: 'Online',
        offline: 'Offline',
      },
      performanceStats: {
        title: 'Performance',
        averageLatency: 'Avg Response',
        requestsPerSecond: 'Requests/Second',
        averageLatencyValue: '%{time} ms',
      },
      blocksStats: {
        title: 'Blocks',
        applications: 'Apps',
        content: 'Content',
        whiteBlackList: 'Blacklist',
        threats: 'Threats',
      },
      requestsStats: {
        title: 'Allowed/Blocked',
        allowed: 'Allowed',
        blocked: 'Blocked',
        total: 'Total',
        graphDataNotAvailable: 'Graph data not available',
      },
      categories: {
        allowedTitle: 'Top Categories Allowed',
        blockedTitle: 'Top Categories Blocked',
        dataNotAvailable: 'Data not available',
        categoryHeader: 'Category',
        countHeader: 'Count',
      },
      domains: {
        allowedTitle: 'Top Domains Allowed',
        blockedTitle: 'Top Domains Blocked',
        dataNotAvailable: 'Data not available',
        domainHeader: 'Domain',
        countHeader: 'Count',
      },
    },
    configureSingleSignOnPage: {
      authProviders: {
        MICROSOFT_ENTRA_ID: 'Microsoft Entra ID',
      },
      createTitle: 'Create SSO connection',
      detailsTitle: 'SSO is configured for this account',
      copyCallbackURLToBufferMessage: 'The callback URL has been copied to the clipboard',
      copyLoginURLToBufferMessage: 'The login URL has been copied to the clipboard',
      buttons: {
        deleteSingleSignOn: 'DELETE CONNECTION',
        editSingleSignOn: 'EDIT CONNECTION',
        enableSingleSignOn: 'Enable connection',
      },
      help: {
        selectedProviderAdminRoles: 'Choose provider roles '
          + 'that would correspond to ScoutDNS Admin role',
        selectedProviderViewerRoles: 'Choose provider roles '
          + 'that would correspond to ScoutDNS Viewer role',
        loginURL: 'Use this URL to login through SSO into ScoutDNS.',
      },
      labels: {
        loginURL: 'Login URL',
        callbackURL: 'Callback URL',
        oauthTenantId: 'Tenant ID',
        clientId: 'Client ID',
        selectedProviderAdminRoles: 'Provider roles as the Admin Role in ScoutDNS',
        selectedProviderViewerRoles: 'Provider roles as the Viewer Role in ScoutDNS',
        authenticationProvider: 'Authentication Provider',
      },
      placeholders: {
        callbackURL: 'Callback URL',
        oauthTenantId: 'Tenant ID',
        clientId: 'Client ID',
        selectedProviderAdminRoles: 'Provider roles as the Admin Role in ScoutDNS',
        selectedProviderViewerRoles: 'Provider roles as the Viewer Role in ScoutDNS',
      },
      errorMessages: {
        configureSingleSignOnPageProviderRoleIsNotValid: 'Provider role should be selected',
        configureSingleSignOnPageClientIDIsNotValid: 'Client ID should not be empty',
        configureSingleSignOnPageClientSecretIsNotValid: 'Client secret should not be empty',
        configureSingleSignOnPageDiscoveryURLIsNotValid: 'Discovery URL should be valid '
          + '(url, not empty)',
        configureSingleSignOnPageOauthTenantIdIsNotValid: 'Tenant ID should not be empty',
        configureSingleSignOnPageProviderAdminRolesAreNotValid: 'Selected provider admin roles '
          + 'should not be empty',
        configureSingleSignOnPageProviderViewerRolesAreNotValid: 'Selected provider viewer '
          + 'roles should not be empty',
      },
    },
    logPage: {
      filtersPanel: {
        title: 'Create logs',
        all: 'All',
        labels: {
          datepickerLabel: 'Date',
          timePickerLabel: 'Time start-end',
          campusLabel: 'Site',
          policyLabel: 'Policy',
          domainLabel: 'Domain',
          filterTypeLabel: 'Filter Type',
          decisionLabel: 'Decision',
          categoryLabel: 'Category Type',
          networkIpLabel: 'WAN IP',
          subnetIpLabel: 'LAN IP',
          clientLabel: 'Client Name',
          organizationLabel: 'Organization',
        },
        placeholders: {
          domainPlaceholder: 'Free text',
          datePickerPlaceholder: 'MM/DD/YYYY',
          timePickerPlaceholder: 'HH:MM AM or HH:MM PM',
          networkIpPlaceholder: 'IP Address/Mask',
          subnetIpPlaceholder: 'IP Address/Mask',
          agentIdPlaceholder: 'UUID',
        },
        buttons: {
          buttonExport: 'EXPORT',
          buttonReset: 'RESET',
          buttonSubmit: 'SUBMIT',
        },
        advancedOptions: 'Advanced options',
      },
      loggingForm: {
        name: 'Additional options',
      },
      table: {
        title: 'Logging table',
        hideFiltersButton: 'Hide filters',
        showFiltersButton: 'Show filters',
        dateTimeFilterPlaceholder: 'Search by date',
        campusNameFilterPlaceholder: 'Search by name',
        dateTimeHeader: 'Date/Time',
        campusNameHeader: 'Site name',
        additionalOptionsHeader: 'Additional options',
        resolver: 'Resolver',
        decision: 'Decision',
        reason: 'Reason',
        domainName: 'FQDN',
        policyName: 'Policy',
        categories: 'Categories',
        networkIp: 'WAN IP',
        subnetIp: 'LAN IP',
        latency: 'Latency',
        networkName: 'WAN name',
        subnetName: 'LAN Name',
        filterType: 'Filter type',
        dnsType: 'Record type',
        apply: 'Apply',
        inspect: 'Inspect',
        agentId: 'Agent Id',
        clientUsername: 'Client Username',
        clientDomain: 'Client Domain',
        clientHostname: 'Client Hostname',
        sourceType: 'Source Type',
        policyType: 'Policy Type',
        organizationName: 'Organization',
      },
      info: {
        logIsEmpty: 'Log is empty',
        logHasBeenDownload: 'Log has been sent to your email',
      },
      breadcrumbs: {
        logs: 'Logs',
        response: 'Response',
      },
      reports: {
        name: 'Reports',
      },
      errorMessages: {
        loadReportError: 'Download audit report error',
        reportNotFound: 'Download audit report error: Not found',
        reportAccessForbidden: 'Download audit report error: Access forbidden',
        reportInvalidData: 'Download audit report error: Invalid request',
      },
    },
    responseView: {
      view: 'VIEW',
      details: {
        id: 'ID',
        question: 'Question',
        answerRRs: 'Answer RRs',
        authorityRRs: 'Authority RRs',
        additionalRRs: 'Additional RRs',
        control: 'Control',
        qr: 'QR',
        opcode: 'OpCode',
        queries: 'Queries',
        queryRow: '%{domain}: type %{type}, class %{dnsClass}',
        answers: 'Answers',
        answerRow: '%{domain}: type %{recordType}, class %{dnsClass}, content %{content}',
      },
      detailsList: {
        domain: 'Name:',
        domainName: 'Name:',
        recordType: 'Type:',
        dnsType: 'Type:',
        dnsClass: 'Class:',
        cname: 'Primary name:',
        ttl: 'Time to live:',
        length: 'Data length:',
        primaryName: 'Primary name:',
        content: 'Content:',
      },
      noData: 'No response data',
    },
    notificationsManagementPage: {
      types: {
        'system': 'System',
        'block-page': 'Block page',
      },
      subtypes: {
        'failure-updating-of-dynamic-dns': 'Dynamic DNS IP Update Failure',
        'updating-of-dynamic-dns': 'Dynamic DNS IP Change',
        'request-to-unblock-page': 'Unblock Page Request',
      },
      table: {
        title: 'Notification profiles',
        email: 'E-mail',
        description: 'Description',
        notifications: 'Notifications',
        emailFilterPlaceholder: 'Search by e-mail',
      },
      notificationProfileInfo: {
        email: 'E-mail',
        description: 'Description',
        notifications: 'Notifications',
      },
      newNotificationProfile: {
        title: 'New notification profile',
        errorMessages: {
          email: 'E-mail is not valid',
        },
      },
      delete: {
        button: 'Delete',
      },
    },
    accessManagementPage: {
      [accessManagementTabs.OPERATORS]: {
        name: 'Operators',
      },
      [accessManagementTabs.SSO]: {
        name: 'Single Sign-On',
      },
      title: 'Operators',
      table: {
        search: 'Search...',
        headers: {
          email: 'E-mail',
          firstName: 'First name',
          lastName: 'Last name',
          role: 'Role',
          authProviderType: 'AuthProvider',
          lastLogin: 'Last login',
        },
      },
      operatorInfo: {
        email: 'E-mail',
        firstName: 'First name',
        lastName: 'Last name',
        lastLogin: 'Last login',
        role: 'Role',
        newSuperAdminRole: 'New role of the current Super Admin',
        assignNewSuperAdmin: {
          title: 'Are you sure you want to change Super Admin of the account?',
          label: 'Select new Super Admin',
          description: 'An account can only have one Super Admin. '
            + 'By promoting a user to Super Admin your role will be downgraded to %{newRole}. '
            + 'Only the new Super Admin can undo or change your role back.',
          warning: 'Once saved your role will be downgraded to %{newRole} and you cannot '
            + 'reverse this change.',
        },
      },
      newOperator: {
        title: 'New operator',
        errorMessages: {
          email: 'E-mail is not valid',
          firstName: 'This field shouldn\'t be empty',
          lastName: 'This field shouldn\'t be empty',
          role: 'This field shouldn\'t be empty',
        },
      },
      revokeAccess: {
        button: 'Revoke access',
        confirmation: 'Are you sure you want to delete operator?',
      },
      promoteNewSuperAdmin: 'Promote new Super Admin',
      force2FA: 'Force 2FA',
      organizations: {
        title: 'Organizations',
        permissions: {
          [ORGANIZATION_OPERATOR_PERMISSIONS.MANAGER]: 'Manager',
          [ORGANIZATION_OPERATOR_PERMISSIONS.VIEWER]: 'Viewer',
        },
        buttons: {
          buttonNew: {
            text: 'ADD ORGANIZATION',
            textShort: 'ADD',
          },
        },
      },
    },
    apiAccessManagementPage: {
      title: 'API Keys',
      table: {
        headers: {
          id: 'ID',
          createdAt: 'Created At',
          lastAccessAt: 'Last Access',
          lastIp: 'Last IP',
          description: 'Description',
        },
        copyToBufferMessage: 'The key has been copied to the clipboard',
      },
      form: {
        newKey: {
          title: 'New API Key',
        },
        labels: {
          id: 'ID',
          createdAt: 'Created At',
          lastAccessAt: 'Last Access',
          lastIp: 'Last IP',
          description: 'Description',
        },
        buttons: {
          revokeKey: 'Revoke Key',
        },
      },
    },
    profilePage: {
      title: 'Profile info',
      passwordBlock: {
        title: 'Change password',
        currentPassword: 'Current password',
        newPassword: 'New password',
        confirmNewPassword: 'Confirm password',
        changePassword: 'Change password',
        errorMessages: {
          newPasswordsDoNotMatch: 'New and confirm passwords do not match',
          currentPasswordIsNotValid: 'Current password should not be empty',
          newPasswordIsNotValid: 'New password should be longer than %{count} symbols',
          currentPasswordIsIncorrect: 'Current password is incorrect',
          tokenIsIncorrect:
            'Token is incorrect. \nPlease try to go by link again or contact support',
        },
        success: {
          text: 'Password has been changed',
          updated: 'Password has been updated',
          buttonText: 'OK',
        },
        update: 'UPDATE PASSWORD',
        backToLogin: 'Back to login',
      },
      emailBlock: {
        title: 'Email',
        label: 'Email',
        validationError: 'E-mail is not valid',
        notification:
          'E-mail address will be changed after confirmation by e-mail.\n'
          + 'Confirmation link has been sent to a new address: %{email}.',
      },
      generalInfoBlock: {
        title: 'General info',
        labels: {
          firstName: 'First name',
          lastName: 'Last name',
        },
        validationErrors: {
          firstNameIsNotValid: 'First name should not be empty or longer than %{maxLength} symbols',
          lastNameIsNotValid: 'Last name should not be empty or longer than %{maxLength} symbols',
        },
        notification:
          'First and last name have been changed',
      },
    },
    customListsPage: {
      wbLists: 'Allow/Block',
      tldFilter: 'TLD filter',
      policiesAssignedTable: {
        title: 'Policies Assigned',
        noPoliciesFound: 'No policies found',
        allPolicies: 'All policies',
      },
    },
    whitelistPage: {
      listsTableTitle: 'Allow/Block lists',
      listName: 'List name',
      tags: 'Tags',
      listNameFilterPlaceholder: 'Search by name',
      listDescription: 'List description',
      isGlobal: 'Make global',
      tabInfo: 'Information',
      tabAllow: 'Allow',
      tabBlock: 'Block',
      domainsPlaceholder: 'yahoo.com,\n*.facebook.com',
      buttonSave: 'Save',
      buttonCancel: 'Cancel',
      buttonEdit: 'Edit',
      buttonAddNewWBList: 'New List',
      createListTitle: 'New list',
      confirmations: {
        makeGlobal: 'When you save changes the list will be removed from all policies assigned. '
          + 'It cannot be undone automatically. Do you want to make global?',
      },
      errorMessages: {
        nameIsNotValid: 'List name should not be empty',
        domainsListIsNotValid: 'Is not valid domain - %{domain}',
        domainsListMoreThenMaxDomains: 'Maximum count of domains is reached.'
          + 'Count of domains: %{domains}. Maximum count of domains: %{maxDomains}',
        wbListMoreThenMaxWBLists: 'Maximum allow/block lists limit is reached',
      },
    },
    tldListsPage: {
      title: 'TLD Filter lists',
      table: {
        headers: {
          name: 'Name',
          description: 'Description',
          tldTags: 'Tags',
        },
      },
      form: {
        information: 'Information',
        tldLists: 'TLD Lists',
        name: 'Name',
        description: 'Description',
        error: {
          name: 'Filter name should not be empty',
        },
      },
      newList: {
        title: 'New TLD filter',
        errors: {
          maxListsReached: 'Maximum TLD filters limit is reached',
        },
      },
    },
    blockPage: {
      blockPageTableTitle: 'Block Pages',
      blockPageNameHeader: 'Name',
      blockPageNameFilterPlaceholder: 'Search by name',
      blockPageDescriptionHeader: 'Description',
      buttonAddNewBlockPage: 'NEW BLOCK PAGE',
      deleteBlockPage: 'DELETE BLOCK PAGE',
      editBlockPage: 'EDIT BLOCK PAGE',
      createBlockPage: 'New block page',
      editForm: {
        labels: {
          name: 'Name',
          description: 'Description',
          contentHeader: 'Heading',
          contentTitle: 'Page Title',
          contentResponse: 'Response',
          contentHasReviewRequests: 'Review Requests',
          contentHasUserEmailInput: 'Email Input',
          contentIsUserEmailRequired: 'Require Email Input',
          contentNonDefaultEmail: 'Email Address',
          contentHasNonDefaultSendEmail: 'Override Default Email',
          contentShouldHideIPs: 'Hide IP addresses',
          contentHasLogoInserted: 'Insert Logo',
          contentLogoMessage: 'Logo Message',
          contentLogo: 'Upload New Logo',
        },
        deleteLogo: 'Delete Logo',
      },
      errorMessages: {
        blockPageNameIsNotValid: 'Block page name should not be empty',
        blockPageContentTitleIsNotValid: 'Block page content title should not be empty',
        blockPageContentResponseIsNotValid:
          'Block page content response should not be empty',
        blockPageContentHeaderIsNotValid: 'Block page content header should not be empty',
        blockPageContentLogoURLIsNotValid: 'Block page logo should not be empty '
          + 'when \'Insert Logo\' option is enabled',
        blockPageContentNonDefaultEmailIsNotValid: 'Block page non-default email '
          + 'should be valid (email, not empty) when \'Override Default Email\' option is enabled',
      },
    },
    campusPage: {
      campusesTableTitle: 'Sites',
      buttonAddNewCampus: 'New Site',
      campusNameHeader: 'Site name',
      campusNameFilterPlaceholder: 'Search by name',
      upHeader: 'Up',
      downHeader: 'Down',
      requestsPerSecondHeader: 'QPS',
      actionsHeader: '',
      upTableData: '%{up} Up',
      downTableData: '%{down} Down',
      requestsPerSecondTableData: '%{requestsPerSecond} QPS',
      campusName: 'Site name',
      campusAddress: 'Site address',
      errorMessages: {
        campusNameIsNotValid: 'Site name should not be empty',
        campusesListMoreThenMaxCampuses: 'Maximum campuses limit is reached',
      },
      createCampus: 'New site',
      dashboardTitle: 'Dashboard',
      networksTab: {
        tabName: 'WAN',
        title: 'WAN',
        networkNameFilterPlaceholder: 'Search by name',
        statusHeader: 'Status',
        nameHeader: 'WAN',
        dynamicDnsHeader: 'Dynamic DNS',
        publicIpAddressHeader: 'Public IP Address',
        blockPageHeader: 'Block Page',
        policyHeader: 'Policy',
        stateHeader: 'State',
        policiesDropdownHeader: 'Policies',
        createNetwork: 'New WAN',
        networkNameLabel: 'WAN name',
        networkNetAddressLabel: 'Net address (IPv4, IPv6 or DynDNS)',
        networkPolicyLabel: 'Policy',
        networkBlockPageLabel: 'Block Page',
        networkStateLabel: 'State',
        errorMessages: {
          networkNameIsNotValid: 'WAN name should not be empty',
          ipIsNotValid: 'WAN address should be IPv4, IPv6 or DynDNS',
          cannotGetNetworksCount: 'Can\'t get WANs count',
          networksListInCampusMoreThenNetworkPerCampus:
            'Maximum WANs in campus limit is reached',
          networksListMoreThenMaxNetworks: 'Maximum WANs limit is reached',
        },
      },
      subnetsTab: {
        tabName: 'LAN',
        title: 'LAN',
        networkNameFilterPlaceholder: 'Search by WAN name',
        subnetNameFilterPlaceholder: 'Search by LAN name',
        statusHeader: 'Status',
        subnetNameHeader: 'LAN',
        networkNameHeader: 'WAN',
        subnetAddressRangeHeader: 'LAN Address Range',
        policyHeader: 'Policy',
        stateHeader: 'State',
        createSubnet: 'New LAN',
        subnetNameLabel: 'LAN name',
        subnetNetAddressLabel: 'LAN address range (IPv4 or IPv6)',
        subnetPolicyLabel: 'Policy',
        subnetStateLabel: 'State',
        networksLabel: 'WAN',
        networkDefaultPolicyName: '<WAN Default>',
        allWANsOptionLabel: '<All WANs>',
        errorMessages: {
          subnetNameIsNotValid: 'LAN name should not be empty',
          ipIsNotValid: 'LAN address range should be IPv4 or IPv6',
          cannotGetSubnetsCount: 'Can\'t get LAN count',
        },
        messages: {
          relayUsingNotAllowed: 'Function not available for your subscription. '
            + 'Please contact us to enable.',
        },
      },
      campusInfoTab: {
        tabName: 'INFO',
        cardTitle: 'Site info',
      },
      relaysTab: {
        tabName: 'RELAYS',
        title: 'Relays',
        labels: {
          network: 'WAN',
          id: 'ID',
          status: 'Status',
          version: 'Version',
          lastConfigUpdatingAt: 'Last Config',
          actions: 'Actions',
        },
        filterPlaceholders: {
          network: 'Search by WAN name',
          id: 'Search by ID',
          version: 'Search by version',
        },
        messages: {
          relayUsingNotAllowed: 'Function not available for your subscription. '
            + 'Please contact us to enable.',
        },
      },
      localDomainsTab: {
        tabName: 'REDIRECTS',
        title: 'Redirects',
        createLocalDomain: 'New Redirect',
        labels: {
          network: 'WAN',
          domain: 'Domain',
          ip: 'IP',
        },
        allWANsOptionLabel: '<All WANs>',
        filterPlaceholders: {
          network: 'Search by WAN name',
          domain: 'Search by domain',
          ip: 'Search by IP address',
        },
        errorMessages: {
          domainIsNotValid: 'Domain name should be not empty',
          ipIsNotValid: 'Should be valid IPv4 or IPv6 address',
        },
        messages: {
          relayUsingNotAllowed: 'Function not available for your subscription. '
            + 'Please contact us to enable.',
        },
      },
      localForwardingsTab: {
        tabName: 'LOCAL FORWARDING',
        title: 'Local Forwarding',
        createLocalForwarding: 'New Local Forwarding',
        labels: {
          subnet: 'LAN',
          domain: 'Domain',
          ips: 'Local DNS IP',
        },
        allLANsOptionLabel: '<All LANs>',
        filterPlaceholders: {
          subnet: 'Search by LAN name',
          domain: 'Search by domain',
          ips: 'Search by IP',
        },
        errorMessages: {
          domainIsNotValid: 'Domain name should be not empty',
          ipIsNotValid: 'Should be valid IPv4 or IPv6 address',
        },
        messages: {
          relayUsingNotAllowed: 'Function not available for your subscription. '
            + 'Please contact us to enable.',
        },
      },
    },
    policyPage: {
      policyTableTitle: 'Policies',
      policyName: 'Policy',
      policyTags: 'Tags',
      policyDescription: 'Description',
      policyNameFilterPlaceholder: 'Search by name',
      createPolicyTitle: 'New Policy',
      multiSelectionLabel: '%{label} (multi selection)',
      fields: {
        'White/Black list': 'Allow/Block list',
      },
      settingsTab: {
        advancedSection: {
          title: 'Advanced settings',
        },
        baseSection: {
          title: 'Base settings',
          policyNameLabel: 'Policy name',
          policyDescriptionLabel: 'Policy description',
          youtubeSafeSearchModes: {
            g_suite_restricted: 'Moderate (G Suite Supported)',
            restricted: 'Strict (G Suite Supported)',
            unrestricted: 'Unrestricted',
          },
        },
        copyPolicySection: {
          label: 'Copy a policy',
          noPolicy: 'None',
        },
        tabName: 'Settings',
      },
      threatsTab: {
        tabName: 'Threats',
      },
      contentTab: {
        tabName: 'Content',
      },
      applicationsTab: {
        tabName: 'Applications',
      },
      tldTab: {
        tabName: 'TLD',
        enableTldFiltering: 'Enable TLD filtering',
        disableTldFilteringConfirmation: 'Are you sure you want to disable TLD filtering? '
          + 'All lists will be reset.',
        blockListLabel: 'Untrusted',
        permitListLabel: 'Trusted',
        tableHeaders: {
          name: 'Name',
          type: 'Type',
          manager: 'Manager',
        },
        searchFieldLabel: 'Search',
        moveToPermitListButtonSectionLabel: 'Move to permit list: ',
        moveToPermitListButtonLabels: {
          [MOVE_TO_PERMIT_LIST_OPTIONS.ALL]: 'All',
          [MOVE_TO_PERMIT_LIST_OPTIONS.TOP_25]: 'Top 25',
          [MOVE_TO_PERMIT_LIST_OPTIONS.TOP_50]: 'Top 50',
          [MOVE_TO_PERMIT_LIST_OPTIONS.TOP_100]: 'Top 100',
        },
        selectionButtonLabels: {
          selectAll: 'Select all',
          deselect: 'Deselect',
        },
      },
      formComponents: {
        'youtube safe mode': {
          'label': 'Youtube safe mode',
          'unrestricted': 'Unrestricted',
          'g suite restricted': 'Moderate',
          'restricted': 'Strict',
        },
        'safe search': {
          label: 'Safe search',
          off: 'Off',
          on: 'On',
          forced: 'Forced',
        },
      },
    },
    filterTypes: {
      CATEGORY: 'Category',
      DOMAIN_LENGTH: 'Domain length',
      FAIL: 'Fail',
      SPY_MODE: 'Spy mode',
      SAFE_SEARCH: 'Safe search',
      WHITE_BLACK_LIST: 'White/Black list',
    },
    categories: {
      THREATS: 'Threats',
      CONTENT: 'Content',
      APPLICATION: 'Application',
      WHITE_BLACK_LIST: 'White/Black list',
    },
    decisions: {
      ALL: 'ALL',
      ALLOWED: 'ALLOWED',
      FORBIDDEN: 'BLOCKED',
      DROP: 'DROP',
    },
    domainTypes: {
      DOMAIN: 'DOMAIN',
      FQDN: 'FQDN',
    },
    buttons: {
      adopt: 'Adopt',
      forget: 'Forget',
      cancel: 'Cancel',
      deleteEntity: 'Delete %{entityType}',
      editEntity: 'Edit %{entityType}',
      newEntity: 'New %{entityType}',
      export: 'Export CSV',
      refresh: 'Refresh',
      submit: 'Submit',
      reset: 'Reset',
      save: 'Save',
      close: 'Close',
      print: 'Print',
      copy: {
        name: 'Copy',
        confirmation: 'The response has been copied to the clipboard',
      },
    },
    entitiesTypes: {
      apiKey: 'API Key',
      campus: 'site',
      localDomain: 'redirect',
      localForwarding: 'domain',
      network: 'WAN',
      relay: 'relay',
      subnet: 'LAN',
      wbList: 'w/b list',
      policy: 'policy',
      operator: 'operator',
      notificationProfile: 'profile',
      tldFilterList: 'TLD Filter',
      client: 'client',
      clientProfile: 'profile',
      clientProfileLocalForwarding: 'domain',
      clientBundle: 'key',
      organization: 'organization',
    },
    loginPage: {
      username: 'Username',
      password: 'Password',
      login: 'LOG IN',
      errorMessageLoginFailed: 'The email and password you entered did not match our records.\n'
        + 'Please double-check and try again',
      forgotPassword: 'Forgot password?',
    },
    loginPage2FA: {
      codePlaceholder: '6 digit code',
      codeWasSentMessage: 'A code was sent to your email',
      resendCode: 'Resend Code',
      canGetNewCodeAfter: 'You can get new code after',
      getHelp: 'Get Help',
      trustDevice: 'Trust Device',
      login: 'LOG IN',
      errorMessageLoginFailed: 'The code is wrong.\n'
        + 'Please double-check and try again',
    },
    loginPageSSO: {
      microsoftSSO: 'Sign in with Microsoft',
      errorMessageLoginFailed: 'Something went wrong while signing in through SSO.\n'
        + 'Please double-check with your admin that you are allowed '
        + 'to login through SSO and try again.',
    },
    logoutPageSSO: {
      warning: 'You have successfully signed out of our application. '
        + 'However, please be aware that you are still logged in '
        + 'to your Single Sign-On (SSO) provider.',
    },
    subscriptionPage: {
      accountStatus: 'Account status',
      accountType: 'Account type',
      maxCampuses: 'Sites allowed',
      subscriptionContact: {
        text: 'To cancel or modify existing subscription please ',
        link: 'contact Sales',
      },
      maxNetworks: 'WANs allowed',
      maxNetworksPerCampus: 'WANs/Site allowed',
      maxWBLists: 'White/black lists allowed',
      maxDomainsPerWBList: 'Domains/List allowed',
      openTicket: 'Open ticket',
      title: 'Subscription',
    },
    resetPasswordPage: {
      backToLogin: 'Back to login',
      buttonOk: 'OK',
      email: 'Email',
      notifyAboutSentEmail: 'Link to reset password has been sent to the entered email',
      reset: 'RESET PASSWORD',
    },
    tableColumnPicker: {
      title: 'Select columns to show',
    },
    tags: {
      global: 'Global',
      org: 'Org',
      readOnly: 'Read only',
    },
    loading: {
      altText: 'Please, wait...',
    },
    statusIndicator: {
      statuses: {
        [RELAY_STATUSES.ONLINE]: 'Online',
        [RELAY_STATUSES.PROBABLY_OFFLINE]: 'Missed heartbeat',
        [RELAY_STATUSES.OFFLINE]: 'Offline',
        [RELAY_STATUSES.ENABLING]: 'Enabling',
        [RELAY_STATUSES.DISABLING]: 'Disabling',
        [RELAY_STATUSES.NOT_ADOPTED]: 'Not adopted',
        [RELAY_STATUSES.ADOPTING]: 'Adopting',
        [RELAY_STATUSES.UPDATING_CONFIG]: 'Updating config',
        [CLIENT_STATUSES.ONLINE]: 'Online',
        [CLIENT_STATUSES.OFFLINE]: 'Offline',
        [CLIENT_STATUSES.DISABLED]: 'Disabled',
        [CLIENT_STATUSES.UNINSTALL]: 'Uninstall',
        [CLIENT_STATUSES.MISSING]: 'Missing',
      },
    },
    modalWindow: {
      delete: 'Are you sure you want to delete this %{entityType}?',
      buttonConfirm: 'Yes',
      buttonCancel: 'No',
      buttonOk: 'OK',
      deleteSingleSignOnConfiguration: 'Are you sure that you want to delete your SSO connection? '
        + 'You will no longer be able to sign in via your configured identity provider '
        + 'and a new user will need to be created '
        + 'for any users added while SSO was active. This change cannot be undone.',
      forgetRelay: 'Are you sure you want to FORGET this relay? '
        + 'Any client devices dependent on it '
        + 'will loose access to DNS unless another relay is running on the same networks',
      configureSingleSignOn: 'Configuring SSO will impact your users. '
        + 'Admins and viewers will be revoked for this account. '
        + 'Platform users will be granted access in the ScoutDNS system based '
        + 'on their configured role in '
        + 'Microsoft Entra ID. Please visit our knowledgebase for more details.',
      disableClient: 'This will disable ScoutDNS and all DNS traffic will be handled '
        + 'by the default LAN DNS servers. '
        + 'The ScoutDNS agent will remain on the client and can be re-enabled at any time. '
        + 'If you wish to permanently remove the ScoutDNS agent please select '
        + 'the "Uninstall" option instead.',
      forgetClient: 'This will remove the client listing from the UI. '
        + 'If the agent is installed/running it will continue to function normally '
        + 'and will reappear in UI at next sync.',
      uninstallClient: 'This will disable and remove the ScoutDNS agent from '
        + 'the host at next client sync. '
        + 'This action cannot be reversed. Adding client back to ScoutDNS '
        + 'will require a fresh install '
        + 'of the agent back onto the device.',
      disableClientsByIdList: 'This will disable ScoutDNS on selected devices '
        + 'and all DNS traffic will be handled '
        + 'by the default LAN DNS servers. '
        + 'The ScoutDNS agent will remain on the clients and can be re-enabled at any time. ',
      enableClientsByIdList: 'This will enable ScoutDNS on selected '
        + 'Disabled devices and all DNS traffic '
        + 'will be handled by ScoutDNS. '
        + 'This will also cancel any Uninstalls on selected agents.',
      forgetClientsByIdList: 'This will forget the selected devices in the ScoutDNS system, '
        + 'but if the selected devices become active and start sending heartbeats, '
        + 'these devices will reappear in the ScoutDNS system. '
        + 'Please insert “Forget” and hit enter to confirm the forgetting.',
      uninstallClientsByIdList: 'This will uninstall ScoutDNS on selected devices '
        + 'and all DNS traffic will be handled by the default LAN DNS servers. '
        + 'Please insert “Uninstall” and hit enter to confirm the uninstalling. ',
      customButtonCancel: 'Cancel',
      disableClientButtonConfirm: 'Disable',
      forgetClientButtonConfirm: 'Forget',
      uninstallClientButtonConfirm: 'Uninstall',
      disableClientsByIdListButtonConfirm: 'Disable',
      enableClientsByIdListButtonConfirm: 'Enable',
      forgetClientsByIdListButtonConfirm: 'Forget',
      uninstallClientsByIdListButtonConfirm: 'Uninstall',
      forbidAction: 'You don\'t have right to %{entityAction} this %{entityType}. '
        + 'Ask account administrator to do required action',
      forbidActionCreate: 'You don\'t have right to create %{entityType}. '
        + 'Ask account administrator to do that',
    },
    ipList: {
      title: 'IPs list',
      anycast: {
        primary: 'Primary',
        secondary: 'Secondary',
      },
      headers: {
        resolverLocation: 'Resolver location',
        ipv4: 'IPv4',
        ipv6: 'IPv6',
        anycast: 'Anycast IPs',
      },
    },
    systemErrors: {
      internalServerError: 'Internal server error',
    },
    rootCertificatePage: {
      title: 'Root Certificate',
      download: {
        text: 'You can download the root certificate ',
        link: 'here',
      },
      instructions: {
        text: 'Instructions on ',
        link: 'how to import it into your browser',
      },
    },
    downloadRelayPage: {
      title: 'Download Relay Binary',
      text: 'Choose an operation system and your platform and get Relay binary by the link ',
      relayBinaries: {
        labels: {
          os: 'Operation System',
          arch: 'Platform',
          version: 'Relay Version',
          downloadLink: 'Download Link',
        },
        link: 'link',
      },
      messages: {
        relayUsingNotAllowed: 'Function not available for your subscription. '
          + 'Please contact us to enable.',
      },
    },
    noAccountsPage: {
      message: 'Your login is not associated with any account.\n'
        + 'Please contact your organization\'s administrator',
      logoutButton: 'Logout',
    },
    userRoles: {
      [USER_ROLES.SUPER_ADMIN]: 'Super Admin',
      [USER_ROLES.OEM_SUPER_ADMIN]: 'OEM Super Admin',
      [USER_ROLES.OEM_ADMIN]: 'OEM Admin',
      [USER_ROLES.ADMIN]: 'Admin',
      [USER_ROLES.VIEWER]: 'Viewer',
      [USER_ROLES.PROJECT_VIEWER]: 'Project Viewer',
      [USER_ROLES.ORGANIZATION_OPERATOR]: 'Organization operator',
    },
    authenticationProviderTypes: {
      [AUTHENTICATION_PROVIDER_TYPES.SCOUT_DNS]: 'ScoutDNS',
      [AUTHENTICATION_PROVIDER_TYPES.AZURE]: 'Azure',
    },
    emailConfirmationPage: {
      goToApp: 'Go to main page',
      message: {
        successful: 'E-mail address has been successfully updated.',
        unsuccessful: 'E-mail address updating failed.'
          + ' The reason can be that you\'ve already confirmed this e-mail address'
          + ' either the confirmation link is outdated. Please, try again, or contact support.',
      },
    },
    unsubscribingEmailFromNotificationPage: {
      goToApp: 'Go to main page',
      unsubscribe: 'Confirm',
      question: 'Are you sure you want to unsubscribe e-mail '
        + '\'%{email}\' from notification \'%{subtype}\'?',
      message: {
        success: 'E-mail was successfully unsubscribed.',
        failure: 'E-mail unsubscribing failed. Please try again or contact support.',
        invalidInputData: 'E-mail, notification subtype and/or signature are '
          + 'invalid. Please try again or contact support.',
      },
    },
    billingDetails: {
      zohoDetails: 'Subscription details',
      fields: {
        subscriptionID: 'Subscription ID',
        subscriptionName: 'Subscription name',
        customerID: 'Customer ID',
        customerName: 'Customer name',
        lastBillDate: 'Last billing at',
        nextBillDate: 'Next billing at',
      },
      invoices: {
        title: 'Invoices',
        filter: {
          label: 'Filter by invoice status',
          values: {
            All: 'All',
            Paid: 'Paid',
            OverDue: 'OverDue',
            Unpaid: 'Unpaid',
            Void: 'Void',
          },
        },
        table: {
          invoice_date: 'Invoice date',
          invoice_id: 'Invoice #',
          status: 'Status',
          due_date: 'Due date',
          total: 'Amount',
          balance: 'Balance',
        },
      },
    },
    noData: 'No data available',
    insights: {
      [insightsTabs.DOMAIN]: {
        name: 'Domain',
        table: {
          search: 'Search...',
          parentDomain: 'Domain',
          category: 'Categories',
          fqdnCategory: 'FQDN Categories',
          categoryType: 'Category Types',
          fqdnCategoryType: 'FQDN Category Types',
          count: 'Total Count',
          uniqueFqdns: 'Unique FQDNs',
          domain: 'FQDN',
        },
      },
      [insightsTabs.TLD]: {
        name: 'TLD',
        table: {
          name: 'TLD',
          type: 'TLD Type',
          count: 'Count',
        },
      },
      [insightsTabs.CATEGORY]: {
        name: 'Category',
        table: {
          category: 'Category',
          categoryType: 'Category Type',
          count: 'Count',
          percent: 'Percent',
        },
      },
      [insightsTabs.RECORD_TYPE]: {
        name: 'Record Type',
        table: {
          count: 'Count',
          date: 'Date/Time',
          domain: 'Domain',
          resolvedIp: 'Resolved IP',
          category: 'Category',
          recordType: 'Record Type',
        },
      },
      filters: {
        title: 'Filter by',
        placeholder: 'All',
        [insightsFilters.SITE]: 'Site',
        [insightsFilters.CATEGORY]: 'Category',
        [insightsFilters.CATEGORY_TYPE]: 'Category Type',
        [insightsFilters.CATEGORY_SUBTYPE]: 'Category Sub-type',
        [insightsFilters.TLD_TYPE]: 'TLD Type',
        [insightsFilters.POLICY]: 'Policy',
        [insightsFilters.RECORD_TYPE]: 'Record Type',
        [insightsFilters.DOMAIN_NAME]: 'Domain Name',
        [insightsFilters.NETWORK_IP]: 'WAN IP',
        [insightsFilters.SUBNET_IP]: 'LAN IP',
        [insightsFilters.USER]: 'User',
        [insightsFilters.CLIENT]: 'Client',
        [insightsFilters.NETWORK]: 'Network',
        [insightsFilters.PROFILE]: 'Profile',
        actions: {
          clear: 'Clear',
          apply: 'Apply',
        },
        placeholders: {
          [insightsFilters.NETWORK_IP]: 'IP address/mask',
          [insightsFilters.SUBNET_IP]: 'IP address/mask',
          [insightsFilters.USER]: 'Client\'s username',
        },
        disabled: 'Go to the top level to enable filters',
      },
      searchOptions: {
        result: 'Result',
        rows: 'Rows',
        domainType: 'By',
        nameSearch: {
          placeholderPrefix: 'Search',
        },
      },
      rowAction: {
        showLogs: 'Show Logs',
        showDomains: 'Show Domains',
        showFqdns: 'Show FQDNs',
        domainsTitle: 'Domains',
      },
      breadcrumbs: {
        [insightsTabs.DOMAIN]: 'Domains',
        [insightsTabs.TLD]: 'TLDs',
        [insightsTabs.CATEGORY]: 'Categories',
        [insightsTabs.RECORD_TYPE]: 'Record types',
        domains: 'Domains',
        fqdn: 'FQDNs',
        logs: 'Logs',
        response: 'Response',
      },
    },
    clients: {
      messages: {
        agentUsingNotAllowed: 'Function not available for your subscription. '
          + 'Please contact us to enable.',
      },
      [clientsTabs.MANAGE]: {
        name: 'Manage',
        controls: {
          agentStatuses: {
            ALL: 'ALL',
            [DEVICE_AGENT_STATUS_FILTERS.ONLINE]: 'ONLINE',
            [DEVICE_AGENT_STATUS_FILTERS.OFFLINE]: 'OFFLINE',
          },
          onSiteStatuses: {
            BOTH: 'BOTH',
            [ON_SITE_STATUSES.ONSITE]: 'ONSITE',
            [ON_SITE_STATUSES.ROAMING]: 'ROAMING',
          },
          filters: {
            placeholders: {
              clientName: 'Client / Hostname Search',
            },
            allProfilesOption: 'All Profiles',
            allStatusesOption: 'ALL STATUSES',
          },
        },
        roamingLabel: 'ROAMING',
        table: {
          title: 'Clients',
          multiselect: 'MULTY-SELECT',
          clientActions: {
            placeholder: 'Client Actions',
            [CLIENT_ACTIONS.DISABLE]: 'Disable',
            [CLIENT_ACTIONS.ENABLE]: 'Enable',
            [CLIENT_ACTIONS.FORGET]: 'Forget',
            [CLIENT_ACTIONS.UNINSTALL]: 'Uninstall',
          },
          headers: {
            os: 'OS',
            clientName: 'Client Name',
            username: 'User',
            agentStatus: 'Status',
            lastSync: 'Last Sync',
            profile: 'Profile',
            version: 'Version',
            site: 'Site',
            wanIp: 'WAN',
            lanIp: 'LAN',
            policy: 'Policy',
          },
        },
        policyTypes: {
          SITE: 'Site',
          CLIENT: 'Client',
          CLIENT_PROFILE_DEFAULT: 'Default',
          CLIENT_PROFILE_ONSITE: 'Onsite',
          CLIENT_PROFILE_OFFSITE: 'Offsite',
        },
        editClientForm: {
          dashboard: 'Dashboard',
          threats: {
            title: 'Threats',
            noData: 'No Data',
          },
          profileDefined: 'Profile Defined',
          clientInfoTable: {
            title: 'Device Info',
            name: 'Name',
            hostname: 'Hostname',
            fullHostname: 'Full Hostname',
            profile: 'Profile',
            policy: 'Policy',
            lastSync: 'Last Sync',
          },
          networkInfoTable: {
            title: 'Network Info',
            status: 'Status',
            lastWanIp: 'WAN IP',
            lastLanIp: 'LAN IP',
            lastUser: 'Username',
            clientDomain: 'Domain',
            lastSite: 'Site',
          },
          buttons: {
            disable: 'DISABLE',
            enable: 'ENABLE',
            forget: 'FORGET',
            uninstall: 'UNINSTALL',
            cancel: 'CANCEL',
          },
          actionMenuTitle: 'Actions',
          actionMenu: {
            disable: 'Disable',
            enable: 'Enable',
            forget: 'Forget',
            uninstall: 'Uninstall',
            cancel: 'Cancel',
          },
        },
        sessions: {
          title: 'Last Sessions',
          headers: {
            wan: 'WAN IP',
            lan: 'LAN IP',
            site: 'Site',
            username: 'User',
            sessionStart: 'Session Start',
          },
        },
      },
      [clientsTabs.CONFIGURE]: {
        name: 'Configure',
        table: {
          title: 'Profiles',
          headers: {
            name: 'Profile Name',
            description: 'Description',
            clientStats: 'Clients',
            defaultPolicy: 'Default Policy',
          },
        },
        editProfileForm: {
          createProfile: 'New Client Profile',
          tabNames: {
            settings: 'Settings',
            localForwarding: 'Local Forwarding',
            localRedirects: 'Local Redirects',
            install: 'Install',
          },
          settingsTab: {
            tabName: 'Settings',
            labels: {

            },
          },
          localForwardingTab: {
            tabName: 'Local Forwarding',
          },
          localRedirectsTab: {
            tabName: 'Local Redirects',
          },
          installTab: {
            tabName: 'Install',
          },
          labels: {
            profileName: 'Profile Name',
            description: 'Profile Description',
            defaultPolicy: 'Default Policy',
            hideTrayIcon: 'Hide Tray Icon',
            dynamicPolicies: 'Dynamic Policies',
            onsitePolicy: 'Onsite Policy',
            offsitePolicy: 'Offsite Policy',
            defaultBlockPage: 'Block Page',
          },
          siteDefault: 'Site Default',
          profileDefault: 'Profile Default',
          copyProfile: 'Copy a Profile',
          advancedSectionTitle: 'Advanced',
        },
        localForwarding: {
          table: {
            title: 'Local Domains',
            headers: {
              domain: 'Domain',
              resolvers: 'Resolvers',
            },
          },
          createDomain: 'New Domain',
          auto: 'Auto',
          labels: {
            domain: 'Domain',
            resolvers: 'Resolvers',
            useDeviceResolvers: 'Auto',
          },
          errorMessages: {
            domainNotValid: 'Domain should be not empty',
            ipIsNotValid: 'Should be valid IPv4 or IPv6',
          },
        },
        installForm: {
          table: {
            title: 'Active Keys',
            headers: {
              accessKey: 'Key',
              expireDate: 'Expire Date',
              installs: 'Installs',
              os: 'OS',
              architecture: 'Architecture',
              file: 'File',
            },
          },
          createBundle: 'New Key',
          labels: {
            platform: 'Platform',
            architecture: 'Architecture',
            duration: 'Duration',
            installs: 'Installs',
            packagedInstaller: {
              title: 'Packaged Installer',
              download: 'Download',
            },
            scriptInstaller: {
              title: 'Script Installer',
              url: 'URL',
              key: 'Key',
              installCommand: 'Install Command',
            },
          },
          download: 'Download',
          durations: {
            hour1: '1 hour',
            hours24: '24 hours',
            hours48: '48 hours',
            days7: '7 days',
            days30: '30 days',
            indefinite: 'indefinite',
          },
        },
        errorMessages: {
          maxProfilesOnAccountExceeded: 'Max amount of client profiles on account exceeded',
          profileNameNotValid: 'Profile name should be not empty',
          installsCountNotValid: 'Should be a positive number',
        },
      },
    },
    threatStatsWidget: {
      title: 'Threats',
      noData: 'No Data',
    },
    commonRowsActions: {
      addToWhiteList: 'Add to Allow',
      addToBlackList: 'Add to Block',
      confirmation: {
        addToWbList: {
          action: {
            [WB_LIST_TYPE.ALLOW]: 'ALLOW',
            [WB_LIST_TYPE.BLOCK]: 'BLOCK',
          },
          message: 'Are you sure you want to %{action} ',
          inList: ' in list ',
        },
      },
    },
    organizations: {
      page: {
        tabs: {
          organizations: 'Orgs',
          usageBilling: 'Usage/Billing',
        },
      },
      table: {
        title: 'Organizations',
        headers: {
          organizationName: 'Organization',
          sites: 'Sites',
          networks: 'Networks',
          clients: 'Clients',
          users: 'Users',
        },
        placeholders: {
          organizationSearch: 'Organization Search',
        },
      },
      newOrganization: 'New Organization',
      tabs: {
        sites: {
          title: 'Sites',
          inOrganizationLabel: 'Linked Sites',
          notInOrganizationLabel: 'Unlinked Sites',
          editButtonLabel: 'Link',
        },
        profiles: {
          title: 'Profiles',
          inOrganizationLabel: 'Linked Profiles',
          notInOrganizationLabel: 'Unlinked Profiles',
          editButtonLabel: 'Link',
        },
        info: {
          title: 'Info',
        },
      },
      editForm: {
        labels: {
          name: 'Name',
          description: 'Description',
        },
        createOrganization: 'New organization',
      },
      errorMessages: {
        organizationNameIsNotValid: 'Should be not empty',
        organizationsNotAllowed: 'Function not available for your subscription. '
          + 'Please contact us to enable.',
      },
      dashboard: {
        title: 'Dashboard',
        allSites: 'All Sites',
        allProfiles: 'All Profiles',
      },
      sites: {
        table: {
          title: 'Sites',
          headers: {
            sites: 'Site',
            wansUp: 'WANs Up',
            wansDown: 'WANs Down',
            queriesPerSecond: 'QPS',
            requestsAllowed: 'Allowed',
            requestsBlocked: 'Blocked',
            requestsTotal: 'Total',
            actions: 'Unlink',
          },
        },
      },
      profiles: {
        table: {
          title: 'Profiles',
          headers: {
            profile: 'Profile',
            clientsOnline: 'Online Client',
            clientsOffline: 'Offline Clients',
            clientsTotal: 'Total Client',
            requestsAllowed: 'Allowed Requests',
            requestsBlocked: 'Blocked Requests',
            requestsTotal: 'Total Requests',
            actions: 'Unlink',
          },
        },
      },
      usageBilling: {
        settings: {
          buttons: {
            calculate: 'Calculate',
            exportCSV: 'Export CSV',
          },
          labels: {
            period: 'Period',
            periodCovers: 'Covers',
            queryCost: 'Cost/100K queries',
            seatCost: 'Cost/Seat',
          },
          periods: {
            last30Days: 'Last 30 Days',
            invoiceDate: 'Invoice Date',
          },
        },
        table: {
          title: 'Usage/Billing',
          headers: {
            organization: 'Organization',
            networkQueries: 'Network Queries',
            clientQueries: 'Client Queries',
            totalQueries: 'Total Queries',
            networkSeats: 'Network Seats',
            clientSeats: 'Client Seats',
            totalSeats: 'Total Seats',
            queryCost: 'Query Cost',
            seatCost: 'Seat Cost',
          },
          total: 'Total',
        },
        total: 'Total',
      },
    },
    universalDashboard: {
      title: 'Dashboard',
    },
    components: {
      selectableEntityVerticalPairedList: {
        element: {
          headerName: 'Name',
          deselectButton: 'Deselect',
          selectAllButton: 'Select All',
        },
      },
      multiDropdown: {
        noOptions: 'No options available',
      },
    },
    serverErrors: {
      'api-access-keys': {
        'unable-to-get': 'Unable to get API access keys',
        'unable-to-get-by-id': 'Unable to get API access key',
        'unable-to-get-by-id-not-found': 'Unable to get API access key: the key is not found',
        'unable-to-get-by-id-bad-id': 'Unable to get API access key: id contains errors',
        'not-allowed': 'Function not available for your subscription. ' +
          'Please contact us to enable.',
        'unable-to-create': 'Unable to create API access key',
        'unable-to-create-account-limit-exceeded': 'Unable to create API access key: ' +
          'account limit exceeded',
        'unable-to-update': 'Unable to update API access key data',
        'unable-to-update-not-found': 'Unable to update API access key data: ' +
          'the key is not found',
        'unable-to-delete': 'Unable to delete API access key',
        'unable-to-delete-bad-id': 'Unable to delete API access key: bad id',
      },
      'assign-super-admin': {
        'unable-to-assign': 'Unable to assign new super admin',
        'unable-to-assign-form-contains-errors': 'Unable to assign super admin: ' +
          'form contains errors',
      },
      'auth-code': {
        'unable-to-generate': 'Unable to generate auth code',
        'unable-to-check': 'Unable to check auth code',
        'bad-code-format': 'Bad auth code format',
      },
      'clients': {
        'edit': {
          'client-form-contains-errors': 'Client edit form contains errors',
        },
        'delete': {
          'client-form-contains-errors': 'Client form contains errors',
          'client-not-found': 'The client not found',
          'unable-to-delete': 'Unable to delete the client',
        },
        'enable': {
          'unable-to-enable': 'Unable to enable the client',
          'client-not-found': 'The client not found',
        },
        'disable': {
          'unable-to-disable': 'Unable to disable the client',
          'client-not-found': 'The client not found',
        },
        'uninstall': {
          'form-contains-errors': 'Request form contains errors',
          'client-not-found': 'The client not found',
          'unable-to-uninstall': 'Unable to uninstall the client',
        },
        'cancel-uninstall': {
          'form-contains-errors': 'Request form contains errors',
          'client-not-found': 'The client not found',
          'unable-to-cancel': 'Unable to cancel uninstall the client',
        },
        'sessions': {
          'unable-to-get': 'Session stats unavailable. You can continue to work without stats.',
        },
        'unable-to-export-wrong-status': 'Unable to export clients: wrong status',
        'unable-to-export': 'Unable to export clients',
        'unable-to-get': 'Unable to get client list',
        'unable-to-get-count': 'Unable to get client count',
        'unable-to-get-client-names': 'Unable to get client names',
        'unable-to-get-stats': 'Unable to get client stats',
        'unable-to-get-client': 'Unable to get client',
        'unable-to-get-client-not-found': 'Unable to get client: not found',
        'unable-to-get-client-bad-id': 'Unable to get client: id contains errors',
      },
      'client-profiles': {
        'create-profile': {
          'profile-form-contains-errors': 'Client Profile form contains errors',
        },
        'edit-profile': {
          'profile-form-contains-errors': 'Client Profile form contains errors',
        },
        'not-allowed': 'Function not available for your subscription. Please contact us to enable.',
        'unable-to-get': 'Unable to get profile list',
        'unable-to-get-profile': 'Unable to get profile',
        'unable-to-get-profile-not-found': 'Unable to get profile: not found',
        'unable-to-create': 'Unable to create profile',
        'unable-to-update': 'Unable to update profile',
        'unable-to-update-not-found': 'Unable to update profile: the profile is not found',
        'unable-to-delete': 'Unable to delete profile',
        'unable-to-delete-has-linked-objects': 'Unable to delete profiles with linked clients',
      },
      'client-bundles': {
        'create-bundle': {
          'bundle-form-contains-errors': 'Install Key form contains errors',
        },
        'edit-bundle': {
          'bundle-form-contains-errors': 'Install Key form contains errors',
        },
        'unable-to-get': 'Unable to get install keys',
        'unable-to-get-bad-profile-id': 'Unable to get install keys: profile id contains errors',
        'unable-to-get-bundle': 'Unable to get install key',
        'unable-to-get-bundle-bad-id': 'Unable to get install key: the key\'s id contains errors',
        'unable-to-get-bundle-not-found': 'Unable to get install key: not found',
        'unable-to-create': 'Unable to create install key',
        'unable-to-update': 'Unable to update install key',
        'unable-to-update-not-found': 'Unable to update install key: not found',
        'unable-to-delete': 'Unable-to-delete install key',
        'unable-to-delete-bad-id': 'Unable-to-delete install key: the key\'s id contains errors',
      },
      'universal-installers': {
        'unable-to-get': 'Unable to get script installers info: not found',
      },
      'client-local-forwarding': {
        'create-localForwarding': {
          'localForwarding-form-contains-errors': 'Local forwarding form contains errors',
        },
        'edit-localForwarding': {
          'localForwarding-form-contains-errors': 'Local forwarding form contains errors',
        },
        'unable-to-get': 'Unable to get local domains',
        'unable-to-get-domain': 'Unable to get local domain',
        'unable-to-get-domain-bad-id': 'Unable to get local domain: domain id contains errors',
        'unable-to-get-domain-bad-profile-id': 'Unable to get local domain: ' +
          'profile id contains errors',
        'unable-to-get-domain-not-found': 'Unable to get local domain: the domain not found',
        'unable-to-create': 'Unable to create local domain',
        'unable-to-update': 'Unable to update local domain',
        'unable-to-update-not-found': 'Unable to update local domain: the domain not found',
        'unable-to-delete': 'Unable to delete local domain',
        'unable-to-delete-bad-id': 'Unable to delete local domain: ' +
          'the domain\'s id contains errors',
      },
      'device-agent': {
        'not-allowed': 'Function not available for your subscription. ' +
          'Please contact us to enable.',
      },
      'insights': {
        'category-stats': {
          'unable-to-get': 'Insights unavailable. You can continue to work ' +
            'with other parts of the site.',
        },
        'domain-stats': {
          'unable-to-get': 'Insights unavailable. You can continue to work ' +
            'with other parts of the site.',
        },
        'record-type-stats': {
          'unable-to-get': 'Insights unavailable. You can continue to work ' +
            'with other parts of the site.',
        },
        'tld-stats': {
          'unable-to-get': 'Insights unavailable. You can continue to work ' +
            'with other parts of the site.',
        },
        'logs': {
          'unable-to-get': 'Insights unavailable. You can continue to work ' +
            'with other parts of the site.',
        },
      },
      'local-domains': {
        'create-localDomain': {
          'localDomain-form-contains-errors': 'Local domain form contains errors',
        },
        'edit-localDomain': {
          'localDomain-form-contains-errors': 'Local domain form contains errors',
        },
        'unable-to-get': 'Unable to get redirects',
        'unable-to-get-domain': 'Unable to get redirect',
        'unable-to-get-domain-not-found': 'Unable to get redirect: not found',
        'unable-to-create': 'Unable to create redirect',
        'unable-to-create-account-limit-exceeded': 'Unable to create redirect: ' +
          'max site limit on account exceeded',
        'name-in-use': 'Unable to create redirect: name in use',
        'unable-to-update': 'Unable to update redirect',
        'unable-to-update-not-found': 'Unable to update redirect: the redirect is not found',
        'unable-to-delete': 'Unable to delete redirect',
      },
      'local-forwarding': {
        'create-localForwarding': {
          'localForwarding-form-contains-errors': 'Local forwarding form contains errors',
        },
        'edit-localForwarding': {
          'localForwarding-form-contains-errors': 'Local forwarding form contains errors',
        },
        'unable-to-get': 'Unable to get local forwarding list',
        'unable-to-get-forwarding': 'Unable to get local forwarding',
        'unable-to-get-forwarding-not-found': 'Unable to get local forwarding: not found',
        'unable-to-create': 'Unable to create local forwarding',
        'unable-to-create-account-limit-exceeded': 'Unable to create local forwarding: ' +
          'max site limit on account exceeded',
        'name-in-use': 'Unable to create local forwarding: name in use',
        'unable-to-update': 'Unable to update local forwarding',
        'unable-to-update-not-found': 'Unable to update local forwarding: ' +
          'the local forwarding is not found',
        'unable-to-delete': 'Unable to delete local forwarding',
        'unable-to-delete-id-with-errors': 'Unable to delete local forwarding: ' +
          'id contains errors',
      },
      'locations': {
        'unable-to-get': 'Unable to get sites',
        'unable-to-get-location': 'Unable to get site',
        'unable-to-get-location-not-found': 'Unable to get site: not found',
        'unable-to-create': 'Unable to create site',
        'unable-to-create-account-limit-exceeded': 'Unable to create site: ' +
          'max site limit on account exceeded',
        'name-in-use': 'Unable to create site: name in use',
        'unable-to-update': 'Unable to update site',
        'unable-to-update-not-found': 'Unable to update site: the site is not found',
        'unable-to-delete': 'Unable to delete site',
      },
      'logs': {
        'log-table': {
          'unable-to-get-logs': 'Logs unavailable. ' +
            'You can continue to work with other parts of the site.',
        },
        'response-view': {
          'unable-to-get': 'Response stats unavailable. You can continue to work without stats.',
        },
        'csv-export': {
          'unable-to-export': 'Export service unavailable. Try it later.',
        },
      },
      'manage-mfa': {
        'unable-to-change-state': 'Unable to switch 2FA status',
        'not-allowed': 'Function not available for your subscription. ' +
          'Please contact us to enable.',
      },
      'networks': {
        'status': {
          'unable-to-get': 'Unable to get network status. You can continue to work.',
        },
        'create-network': {
          'network-form-contains-errors': 'WAN form contains errors',
        },
        'edit-network': {
          'network-form-contains-errors': 'WAN form contains errors',
        },
        'unable-to-get': 'Unable to get networks',
        'unable-to-get-network': 'Unable to get network',
        'unable-to-get-network-not-found': 'Unable to get network: not found',
        'unable-to-create': 'Unable to create network',
        'unable-to-create-account-limit-exceeded': 'Unable to create network: ' +
          'max limit exceeded',
        'name-in-use': 'Unable to create network: name in use',
        'unable-to-update': 'Unable to update network',
        'unable-to-edit': 'Unable to edit network',
        'unable-to-create-network-ip-or-domain-in-use': 'Unable to create network: ' +
          'ip address or domain name is already in use',
        'unable-to-edit-network-ip-or-domain-in-use': 'Unable to edit network: ' +
          'ip address or domain name is already in use',
        'unable-to-update-not-found': 'Unable to update network: the network is not found',
        'unable-to-delete': 'Unable to delete network',
        'unable-to-delete-has-linked-objects': 'Unable to delete network with subnets',
      },
      'operators': {
        'unable-to-get': 'Unable to get operators',
        'unable-to-get-operator': 'Unable to get operator',
        'unable-to-get-operator-not-found': 'Unable to get operator: not found',
        'unable-to-create': 'Unable to create operator',
        'unable-to-create-form-contains-errors': 'Unable to create operator: form contains errors',
        'unable-to-revoke-access': 'Unable to revoke access',
        'unable-to-revoke-access-bad-id': 'Unable to revoke access: ' +
          'the operator\'s id contains errors',
        'unable-to-update': 'Unable to update operator',
        'unable-to-update-form-contains-errors': 'Unable to update operator: form contains errors',
      },
      'organizations': {
        'list': {
          'unable-to-get': 'Cannot get organization list. Let\'s try later.',
        },
        'stats': {
          'unable-to-get-network-stats': 'Cannot get network stats. ' +
            'You can continue to work without stats',
          'unable-to-get-request-stats': 'Cannot get request stats. ' +
            'You can continue to work without stats',
        },
        'locations': {
          'unable-to-get': 'Unable get organization sites',
          'unable-to-update': 'Unable update organization sites',
          'unable-to-delete': 'Unable delete site from organization',
          'unable-to-update-organizations-not-found': 'Unable to update sites: ' +
            'the organization not found',
        },
        'profiles': {
          'device-agent-not-allowed': 'Roaming client function not available ' +
            'for your subscription. Please contact us to enable.',
          'unable-to-get': 'Unable to get organization client profiles',
          'unable-to-update': 'Unable to update organization client profiles',
          'unable-to-delete': 'Unable to delete client profile from organization',
        },
        'not-allowed': 'Function not available for your subscription. ' +
          'Please contact us to enable.',
        'unable-to-create': 'Unable to create organization',
        'unable-to-create-name-in-use': 'Unable to create organization: the name is used',
        'unable-to-create-limit-exceeded': 'Unable to create organization: count limit exceeded',
        'unable-to-update': 'Unable to edit organization',
        'unable-to-update-name-in-use': 'Unable to edit organization: the name is used',
        'unable-to-delete': 'Unable to delete organization',
        'form-contains-errors': 'Organization form contains errors',
        'id-contains-errors': 'Organization id contains errors',
        'not-found': 'Organization not found. ' +
          'Return to the organization list and refresh the page',
      },
      'blockpages': {
        'unable-to-delete-blockpage-not-found': 'Unable to delete block page: not found',
        'unable-to-delete-default-blockpage': 'Unable to delete block page: ' +
          'default block page cannot be deleted',
        'unable-to-delete-used-in-networks-or-profiles': 'Unable to delete block page: ' +
          'it is used by networks or profiles',
        'unable-to-delete': 'Unable to delete block page',
        'form-contains-errors': 'Block page form contains errors',
        'unable-to-create-name-in-use': 'Unable to create block page: name is already used',
        'unable-to-create-limit-exceeded': 'Unable to create block page: limit exceeded',
        'unable-to-create': 'Unable to create block page',
        'unable-to-assign-to-network': 'Unable to assign block page to network',
        'unable-to-edit-blockpage-not-found': 'Unable to edit block page: not found',
        'unable-to-edit-name-of-default-blockpage': 'Unable to edit block page: ' +
          'default block page name cannot be changed',
        'unable-to-edit-name-in-use': 'Unable to edit block page: name is already used',
        'unable-to-edit': 'Unable to edit block page',
        'unable-to-upload-image': 'Unable to upload image. ' +
          'Please select a PNG or JPG file with a maximum size of 10 MB.',
        'unable-to-get-blockpage': 'Unable to get block page',
        'unable-to-get-blockpage-not-found': 'Unable to get block page: not found',
        'unable-to-get-blockpage-bad-id': 'Unable to get block page: bad block page id provided',
        'unable-to-get-default-blockpage': 'Unable to get default block page',
        'unable-to-get-default-blockpage-not-found': 'Unable to get default block page data: ' +
          'form will not be prefilled',
        'unable-to-get': 'Unable to get block pages',
      },
      'sso-configuration': {
        'unable-to-get': 'Unable to get SSO configurations',
        'unable-to-get-sso-configuration-not-found': 'Unable to get SSO configuration: not found',
        'unable-to-create': 'Unable to create SSO configuration',
        'unable-to-create-limit-exceeded': 'Unable to create SSO configuration: ' +
          'SSO is already configured',
        'unable-to-edit': 'Unable to edit SSO configuration',
        'unable-to-edit-sso-configuration-not-found': 'Unable to edit SSO configuration: ' +
          'not found',
        'unable-to-delete': 'Unable to delete SSO configuration',
        'unable-to-delete-sso-configuration-not-found': 'Unable to delete SSO configuration: ' +
          'not found',
        'unable-to-delete-sso-configuration-bad-id': 'Unable to delete SSO configuration: ' +
          'bad SSO configuration id provided',
        'form-contains-errors': 'SSO configuration form has errors',
      },
      'policies': {
        'unable-to-get': 'Unable to get policies',
        'unable-to-get-policy': 'Unable to get policy',
        'unable-to-get-policy-not-found': 'Unable to get policy: not found',
        'unable-to-create': 'Unable to create policy',
        'unable-to-update': 'Unable to update policy',
        'unable-to-update-not-found': 'Unable to update policy: the policy not found',
        'unable-to-delete': 'Unable to delete policy',
        'has-linked-objects': 'Unable to delete policy which is in use',
        'name-in-use': 'Policy name in use',
        'unable-to-get-by-client-profile': 'Unable to get policies',
        'unable-to-get-by-client-profile-profile-not-found': 'Unable to get policies: ' +
          'client profile not found',
        'unable-to-get-by-location': 'Unable to get policies',
        'unable-to-get-by-location-location-not-found': 'Unable to get policies: ' +
          'location not found',
        'unable-to-get-policy-template': 'Unable to get policy template',
      },
      'stats': {
        'average-latency-stats': {
          'unable-to-get': 'Performance stats unavailable. ' +
            'You can continue to work without stats.',
        },
        'category-stats': {
          'unable-to-get': 'Category stats unavailable. ' +
            'You can continue to work without stats.',
        },
        'client-stats': {
          'unable-to-get': 'Client stats unavailable. You can continue to work without stats.',
        },
        'domain-stats': {
          'unable-to-get': 'Domain stats unavailable. You can continue to work without stats.',
        },
        'locations-stats': {
          'unable-to-get': 'Site stats unavailable. You can continue to work without stats.',
        },
        'performance-stats': {
          'unable-to-get': 'Performance stats unavailable. ' +
            'You can continue to work without stats.',
          'wrong-period': 'Wrong time period.',
        },
        'request-graph-data': {
          'unable-to-get': 'Request graph unavailable. You can continue to work without stats.',
        },
        'request-stats': {
          'unable-to-get': 'Request stats unavailable. You can continue to work without stats.',
          'wrong-period': 'Wrong time period.',
        },
        'requests-by-decision-stats': {
          'unable-to-get': 'Request stats unavailable. You can continue to work without stats.',
        },
        'requests-per-network-stats': {
          'unable-to-get': 'Request stats unavailable. You can continue to work without stats.',
        },
        'requests-per-second-stats': {
          'unable-to-get': 'Performance stats unavailable. ' +
            'You can continue to work without stats.',
        },
        'threat-stats': {
          'unable-to-get': 'Threat stats unavailable. You can continue to work without stats.',
        },
      },
      'relays': {
        'unable-to-get': 'Unable to get relay list',
        'unable-to-adopt': 'Unable to adopt subnet',
        'unable-to-update': 'Unable to update relay',
        'unable-to-update-not-found': 'Unable to update relay: the relay is not found',
        'unable-to-delete': 'Unable to delete relay',
      },
      'subnets': {
        'create-subnet': {
          'subnet-form-contains-errors': 'LAN form contains errors',
        },
        'edit-subnet': {
          'subnet-form-contains-errors': 'LAN form contains errors',
        },
        'unable-to-get': 'Unable to get subnets',
        'unable-to-get-subnet': 'Unable to get subnet',
        'unable-to-get-subnet-not-found': 'Unable to get subnet: not found',
        'unable-to-create': 'Unable to create subnet',
        'unable-to-create-account-limit-exceeded': 'Unable to create subnet: ' +
          'max limit on account exceeded',
        'name-in-use': 'Unable to create subnet: name in use',
        'unable-to-update': 'Unable to update subnet',
        'unable-to-update-not-found': 'Unable to update subnet: the subnet is not found',
        'unable-to-delete': 'Unable to delete subnet',
      },
      'tld-filters': {
        'delete-tld-filter': {
          'assigned-to-policies': 'Can not delete TLD filter ' +
            'because it is assigned to some policies',
        },
        'unable-to-get': 'Unable to get TLD filters',
        'unable-to-get-filter': 'Unable to get TLD filter',
        'unable-to-create': 'Unable to create TLD filter',
        'unable-to-update': 'Unable to update TLD filter',
        'unable-to-delete': 'Unable to delete TLD filter',
        'account-limit-exceeds': 'Unable to create TLD filter: max limit on account exceeds',
        'name-in-use': 'Filter name in use',
        'has-linked-objects': 'Unable to delete filter since it has linked policies',

      },
      'white-black-lists': {
        'unable-to-get-list': 'Unable to get w/b list',
        'unable-to-get': 'Unable to get w/b lists',
        'unable-to-create': 'Unable to create w/b list',
        'account-limit-exceeds': 'Unable to create w/b list: max limit on account exceeds',
        'domains-on-account-limit-exceeds': 'Unable to add domain to list: ' +
          'max limit on account exceeds',
        'name-in-use': 'List name in use',
        'unable-to-update': 'Unable to update w/b list',
        'unable-to-delete': 'Unable to delete w/b list',
        'unable-to-add-item': 'Unable to add item to w/b list',
        'has-linked-objects': 'Unable to delete list since it has linked policies',
      },
      'usage-billing': {
        'unable-to-get-stats': 'Unable to get Usage/Billing stats',
        'unable-to-export-stats': 'Unable to export Usage/Billing stats',
        'unable-to-get-stats-bad-dates': 'Unable to get Usage/Billing stats: ' +
          'bad input dates format',
        'unable-to-get-stats-bad-costs': 'Unable to get Usage/Billing stats: bad cost format',
      },
      'user': {
        'unable-to-get-accounts': 'Unable to get account list',
        'unable-to-get-organizations': 'Unable to get organization list',
        'unable-to-switch-account': 'Unable to switch account',
        'whoami-failure': 'Unable to get whoami data',
      },
      'read-only': 'You have read-only rights in this view for objects that were created ' +
        'in the "All organizations" view, if you want to edit these objects, ' +
        'you must select the "All organizations" view',
      'unauthorized': 'You have no permission',
      'default': 'Something went wrong',
    },
  },
};
